import request from "../../utils/request";
let baseUrl = process.env.VUE_APP_BASE_URL2;

//获取供应商列表
export function querySmartSpectacularsEquipmentPage(params) {
    return request({
      url: `${baseUrl}/op/spectacularsEquipment/querySmartSpectacularsEquipmentPage/v276`,
      method: "POST",
      data: params
    });
  }


  //获取数智营 看板管理-已绑定设备数
export function countSpectacularsEquipment(params) {
  return request({
    url: `${baseUrl}/op/spectacularsEquipment/countSpectacularsEquipment/v276`,
    method: "POST",
    data: params
  });
}

//查询看板列表
export function querySpectacularsPage(params) {
  return request({
    url: `${baseUrl}/op/spectaculars/querySpectacularsPage/v260`,
    method: "POST",
    data: params
  });
}


//禁用看板设备
export function updateUseSpectacularsEquipment(params) {
  return request({
    url: `${baseUrl}/op/spectacularsEquipment/updateUseSpectacularsEquipment/v261`,
    method: "POST",
    data: params
  });
}


//编辑看板设备
export function updateSpectacularsEquipment(params) {
  return request({
    url: `${baseUrl}/op/spectacularsEquipment/updateEquipment/v261`,
    method: "POST",
    data: params
  });
}

//设置看板
export function setSpectacularsEquipment(params) {
  return request({
    url: `${baseUrl}/op/spectacularsEquipment/setSpectacularsEquipment/v260`,
    method: "POST",
    data: params
  });
}

// 获取下拉看板列表 数智营-我的设备看板下拉
export function querySpectacularsList(params) {
  return request({
    url: `${baseUrl}/op/spectaculars/querySpectacularsList/v276`,
    method: "POST",
    data: params
  });
}

// 数智营-设置看板下拉
export function querySpectacularsSetList(params) {
  return request({
    url: `${baseUrl}/op/spectaculars/querySpectacularsSetList/v276`,
    method: "POST",
    data: params
  });
}
// 数智营 看板管理-我的看板设置列表
export function querySpectacularsEquipmentSet(params) {
  return request({
    url: `${baseUrl}/op/spectacularsEquipment/querySpectacularsEquipmentSet/v260`,
    method: "POST",
    data: params
  });
}

// 数智营 看板管理-取消设置我的看板
export function unSetSpectacularsEquipment(params) {
  return request({
    url: `${baseUrl}/op/spectacularsEquipment/unSetSpectacularsEquipment/v260`,
    method: "POST",
    data: params
  });
}

// // 数智营 看板管理-设置我的看板
// export function setSpectacularsEquipment(params) {
//   return request({
//     url: `${baseUrl}/op/spectacularsEquipment/setSpectacularsEquipment/v260`,
//     method: "POST",
//     data: params
//   });
// }

