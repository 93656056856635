<template>
  <div class="warpList" ref="refWrapper">
    <span @click="getBtLeft" class="btnLeft"></span>
    <span @click="getBtright" class="btnRigth"></span>
    <div class="card-wrapper">
      <div
        ref="s1"
        class="cardLi"
        @click="setCommodityShow(item)"
        v-for="(item, index) in carouselList"
        :key="index"
        :style="{
          transform: 'translateX' + '(' + currentOffset + 'px' + ')',
        }"
      >
        <img :src="item.spectacularsImg"  />
        <p>{{ item.spectacularsName }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["carouselList"],
  data() {
    return {
      currentOffset: 0,
      windowSize: 5,
      paginationFactor: 0,
      // =======
    };
  },
  computed: {},
  created() {},
  methods: {
    setCommodityShow(row){
       this.$emit("setCommodityShow",row)
    },
    // ======
    moveCarousel(num) {
      let add = this.$refs.s1[0].clientWidth;
      console.log(add);
      this.paginationFactor = add;
      if (num == 1) {
        this.currentOffset -= this.paginationFactor + 10;
      } else if (num == 2) {
        this.currentOffset += this.paginationFactor + 10;
      }
    },

    getBtLeft() {
      let add = this.$refs.s1[0].clientWidth; //获取单个
      let num = this.carouselList.length; //总共有多少个
      // console.log(add);
      // console.log(num);
      // console.log(add * num);
      let refWrapper = this.$refs.refWrapper.clientWidth; //获取大盒子的可视距离
      // console.log(refWrapper);
      // 点击次数 =  单个的宽度x总共多少个 - 最大的盒子宽度 + 内边距 * 单个的宽度
      let sum = (add * num - refWrapper + num * 10) / add; //计算出能位移多少次
      // console.log(sum);
      sum = Math.round(sum);
      sum = sum - this.paginationFactor;
      // console.log(sum);
      if (sum > 0) {
        this.currentOffset -= add + 10;
        this.paginationFactor++;
        // console.log(sum);
      }
    },
    getBtright() {
      let add = this.$refs.s1[0].clientWidth; //获取单个
      console.log(this.paginationFactor);
      if (this.paginationFactor > 0) {
        this.currentOffset += add + 10;
        this.paginationFactor = this.paginationFactor - 1;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.warpList {
  width: 100%;
  height: 90%;
  // box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  .btnLeft {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: 40%;
    left: 12px;
    border-radius: 50%;
    background: url("~@/assets/img/icon_a6.png") no-repeat;
    background-size: 100%;
    cursor: pointer;
    z-index: 999;
  }
  .btnRigth {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: 40%;
    right: 12px;
    border-radius: 50%;
    background: url("~@/assets/img/icon_a5.png") no-repeat;
    background-size: 100%;
    cursor: pointer;
    z-index: 999;
  }

  .card-wrapper {
    width: 200%;
    height: 100%;
    display: flex;
    margin-top: 2.2rem;

    .cardLi {
      transition: transform 150ms ease-out;
      margin-left: 10px;
      width: 280px;
      img {
        width: 100%;
         cursor: pointer;
      }
      p {
        width: 100%;
        text-align: center;
      }
     
    }
  }
}

 .cardLi img:hover {
        opacity: 0.8;
      }
</style>