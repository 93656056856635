<template>
  <div class="supploerList">
    <!-- 设备数量监控 -->
    <div class="warpFun meRow">
      <!-- <div class="recordList">
        <div class="recordText">
          <span>192.128.1.110</span>
          <p>我的IP</p>
        </div>
        <div class="recordImg"></div>
      </div> -->

      <div class="recordList">
        <div class="recordText">
          <span>{{ quipmenList.bindCount }}</span>
          <p>绑定设备</p>
        </div>
        <div class="recordImg"></div>
      </div>
      <div class="recordList">
        <div class="recordText">
          <span>{{ quipmenList.onlineCount }}</span>
          <p>在线设备</p>
        </div>
        <div class="recordImgs"></div>
      </div>
      <!-- <div class="recordList">
        <div class="recordText">
          <span>12</span>
          <p>申请设备</p>
        </div>
        <div class="recordImg"></div>
      </div> -->
    </div>
    <!-- 我的看板 -->
    <div class="boardList meRos">
      <div class="boardHeaer">
        <p>我的看板</p>
        <i></i>
      </div>
      <!-- 看板列表 -->
      <!-- ref=“name” 然后再this.$refs.name -->
      <div ref="warp" class="carousel">
        <div ref="tabRef" class="carouselList">
          <SwitchTheScroll
            :carouselList="carouselList"
            @setCommodityShow="setCommodityShow"
            v-if="carouselList.length > 0"
          ></SwitchTheScroll>
          <div class="table-warning" v-if="carouselList.length == 0"></div>
        </div>
      </div>
    </div>

    <div class="tabshow">
      <div class="operation">
        <p>我的设备</p>
        <i></i>
        <div class="ActionButtons">
          <el-row class="meRon">
            <el-col :span="3">
              <el-input
                v-model="DeviceName"
                placeholder="硬件ID、设备名称"
              ></el-input>
            </el-col>
            <el-col :span="2">
              <el-select v-model="pectaculars" placeholder="看板">
                <el-option
                  v-for="item in spectacularsList"
                  :key="item.spectacularsNo"
                  :label="item.spectacularsName"
                  :value="item.spectacularsNo"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="2">
              <el-select v-model="OnlineStatus" placeholder="在线状态">
                <el-option
                  v-for="item in OnlineStatusList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <!-- <el-col :span="2">
              <el-select v-model="DisabledState" placeholder="禁用状态">
                <el-option
                  v-for="item in DisabledStateList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col> -->
            <el-col :span="3" class="inputs">
              <el-button @click="getQuery" type="primary">查询</el-button>
              <el-button @click="getReset" type="primary">重置</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="tab">
        <el-table
          max-height="630"
          :data="tableData"
          style="width: 100%"
          stripe
          :header-cell-style="{
            'text-align': 'center',
            background: '#EDEEF5',
            color: '#333333',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column prop="equipmentNo" label="硬件ID"> </el-table-column>
          <el-table-column
            prop="equipmentName"
            label="设备名称"
          ></el-table-column>
          <el-table-column prop="createDate" label="绑定时间">
          </el-table-column>
          <!-- <el-table-column prop="settlementPeriod" label="更新时间">
          </el-table-column> -->

          <el-table-column
            prop="spectacularsName"
            label="看板"
          ></el-table-column>
          <el-table-column prop="isOnline" label="设备状态">
            <template slot-scope="scope">
              <span v-if="scope.row.isOnline == 0">离线</span>
              <span class="isColor" v-if="scope.row.isOnline == 1">在线</span>
            </template>
          </el-table-column>

          <el-table-column prop="value1" label="操作" width="200">
            <template slot-scope="scope">
              <!-- <el-button
                class="isColorRed"
                type="text"
                v-if="scope.row.isUse == 1"
                @click="compileSupplier(scope.row, 1)"
                >禁用</el-button
              >
              <el-button
                class="isColor"
                type="text"
                v-if="scope.row.isUse == 0"
                @click="compileSupplier(scope.row, 2)"
                >启用</el-button
              > -->

              <!-- :disabled="!$hasPermission('supplierEdit')" -->

              <el-button type="text" @click="editor(scope.row)">编辑</el-button>
              <el-button type="text" @click="getBoardSet(scope.row)"
                >设置看板</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 30, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <!-- //编辑设备 -->
    <el-dialog
      title="编辑设备名称"
      :visible.sync="dialogVisibleEditor"
      width="500px"
      :before-close="handleCloseEditors"
      :modal-append-to-body="false"
    >
      <el-row style="display: flex; justify-content: center">
        <el-col :span="4" style="line-height: 4rem">设备名称:</el-col>
        <el-col :span="8">
          <el-input v-model="editorBoard" placeholder="输入设备名称"></el-input>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseEditors">取 消</el-button>
        <el-button type="primary" @click="examineSupplier">确 定</el-button>
      </span>
    </el-dialog>
    <!-- //设置看板 -->
    <el-dialog
      title="设置看板"
      :visible.sync="dialogVisibleSet"
      width="500px"
      :before-close="handleCloseSet"
      :modal-append-to-body="false"
    >
      <div style="display: flex; justify-content: center">
        <el-select v-model="editorSet" placeholder="看板">
          <el-option
            v-for="item in editorSetList"
            :key="item.value"
            :label="item.spectacularsName"
            :value="item.spectacularsNo"
          >
          </el-option>
        </el-select>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseSet">取 消</el-button>
        <el-button type="primary" @click="setCommodity">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 看板展示 -->
    <el-dialog
      :visible.sync="dialogVisibleShow"
      width="1037px"
      :before-close="handleCloseShow"
      :modal-append-to-body="false"
      title="看板设置管理"
      center
    >
      <span class="Shows" @click="preview">预览</span>
      <div v-if="tableDataShowList.length > 0">
        <div class="tetx">
          <span class="ShowP">已设置看板</span>

          <!-- -->
        </div>

        <el-table
          max-height="550px"
          :data="tableDataShowList"
          style="width: 100%"
          stripe
          center
          :show-header="false"
        >
          <el-table-column type="index" width="80"> </el-table-column>
          <el-table-column prop="equipmentNo" width="180"> </el-table-column>
          <el-table-column prop="equipmentName" width="120"> </el-table-column>
          <el-table-column prop="createDate" width="180"> </el-table-column>
          <el-table-column prop="spectacularsName" width="180">
          </el-table-column>
          <el-table-column prop="isOnline ">
            <template slot-scope="scope">
              <span v-if="scope.row.isOnline == 0" class="">离线</span>
              <span v-if="scope.row.isOnline == 1" class="isColor">在线</span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <el-button
                class="isColorRed"
                type="text"
                @click="getCancelSetup(scope.row)"
                >取消设置</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="tableDataShowunSetVoList.length > 0">
        <p class="ShowP" style="margin-top: 10px">未设置看板</p>
        <el-table
          max-height="550px"
          :data="tableDataShowunSetVoList"
          style="width: 100%"
          stripe
          :show-header="false"
        >
          <el-table-column type="index" width="80"> </el-table-column>
          <el-table-column prop="equipmentNo" width="180"> </el-table-column>
          <el-table-column prop="equipmentName" width="120"> </el-table-column>
          <el-table-column prop="createDate" width="180"> </el-table-column>
          <el-table-column prop="spectacularsName" width="180">
          </el-table-column>
          <el-table-column prop="isOnline ">
            <template slot-scope="scope">
              <span v-if="scope.row.isOnline == 0">离线</span>
              <span v-if="scope.row.isOnline == 1">在线</span>
            </template>
          </el-table-column>
          <el-table-column prop="address">
            <template slot-scope="scope">
              <el-button
                type="text"
                style="color: #175fdd"
                @click="setUp(scope.row)"
                >设置</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="demo-image__preview" v-if="isPreview">
        <!-- <el-image
        style="width: 100%; height: 100%"
        :src="spectacularsData.spectacularsImg"
        :preview-src-list="srcList"
      >
      </el-image> -->
        <el-image-viewer
          :on-close="
            () => {
              isPreview = false;
            }
          "
          :url-list="srcList"
        />
      </div>

      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseShow">取 消</el-button>
        <el-button type="primary" @click="handleCloseShow">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import {
  querySmartSpectacularsEquipmentPage,
  countSpectacularsEquipment,
  querySpectacularsPage,
  updateUseSpectacularsEquipment,
  updateSpectacularsEquipment, //编辑
  setSpectacularsEquipment, //设置看板
  querySpectacularsList, //下拉看版列表
  querySpectacularsSetList,
  querySpectacularsEquipmentSet,
  unSetSpectacularsEquipment, //取消设置
} from "@/api/supplier/supplierAPI";
import SwitchTheScroll from "@/components/SwitchTheScroll";
export default {
  components: {
    SwitchTheScroll,
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      srcList: [], //大图
      state: "", //状态
      tableData: [],
      keyword: "", //关键字
      pageSize: 10, //每页显示
      currentPage: 1, //当前页
      total: 0,
      shopNo: this.$store.getters.shopNo, //店铺编号
      shopType: this.$store.getters.shopType, //店铺编号
      shopName: this.$store.getters.shopName,
      merchantNo: this.$store.getters.userInfo.merchantNo,
      multipleSelection: [],
      carouselList: [],
      // ====
      marginLeft: 30,
      // ========
      value: "",

      spectacularsList: [], //看板
      OnlineStatusList: [
        //在线状态
        {
          name: "离线",
          value: 0,
        },
        {
          name: "在线",
          value: 1,
        },
      ],
      DisabledStateList: [
        {
          name: "禁用",
          value: 0,
        },
        {
          name: "启用",
          value: 1,
        },
      ], //禁用状态
      pectaculars: null, //看板
      OnlineStatus: null, //在线状态
      DisabledState: null, //禁用状态
      DeviceName: "", //设备名称
      // ======= 设备
      quipmenList: {
        bindCount: 0,
        onlineCount: 0,
      },
      // ============
      dialogVisibleEditor: false,
      editorBoard: "", //编辑看板

      dialogVisibleSet: false, //设置看板
      editorSet: "",
      editorSetList: [],
      getBoardSetId: null,

      // ===========
      // 看板展示
      dialogVisibleShow: false,
      tableDataShowList: [], //已设置
      tableDataShowunSetVoList: [], //未设置

      spectacularsNo: null, //看板编号
      spectacularsData: null, //看板数据
      isPreview: false,
      typeinfo: JSON.parse(sessionStorage.getItem("info")),
    };
  },

  methods: {
    // 预览
    preview() {
      this.isPreview = true;
      this.srcList.push(this.spectacularsData.spectacularsImg);
    },
    async setUp(row) {
      //设置看板
      let obj = {
        params: {
          id: row.id, 
          spectacularsNo: this.spectacularsData.spectacularsNo,
          merchantNo: this.merchantNo,
          channelMerNo:this.typeinfo.channelMerNo
        },
      };
      const { data: res } = await setSpectacularsEquipment(obj);
      this.$message.success(res.msg);

      this.getShow(this.spectacularsData);
    },
    async getCancelSetup(row) {
      //取消设置
      let obj = {
        params: {
          id: row.id,
          merchantNo: this.merchantNo,
          channelMerNo:this.typeinfo.channelMerNo
        },
      };
      const { data: res } = await unSetSpectacularsEquipment(obj);
      this.$message.success(res.msg);
      this.getShow(this.spectacularsData);
    },

    // ==========
    handleCloseShow() {
      this.dialogVisibleShow = false;
    },
    async setCommodityShow(row) {
      // this.spectaculars =row.id
      // this.spectacularsNo = row.spectacularsNo
      this.spectacularsData = row;
      this.dialogVisibleShow = true;
      this.getShow(row);
      //设置看板展示
    },
    async getShow(num) {
      let obj = {
        params: {
          id: num.id,
          merchantNo: this.merchantNo,
          spectacularsNo: num.spectacularsNo,
          channelMerNo:this.typeinfo.channelMerNo,
          merType:this.typeinfo.type
        },
      };
      const { data: res } = await querySpectacularsEquipmentSet(obj);
      this.tableDataShowList = res.data.setVo;
      this.tableDataShowunSetVoList = res.data.unSetVo;
    },
    // ============
    handleCloseSet() {
      this.dialogVisibleSet = false;
      this.editorSet = "";
    },
    getBoardSet(row) {
      this.dialogVisibleSet = true;
      this.getBoardSetId = row.id;
      this.getSpectacularsSetList();
    },
    async getSpectacularsSetList() {
      let obj = {
        params: {
          merType: this.typeinfo.type,
          channelMerNo: this.typeinfo.channelMerNo,
        },
      };
      const { data: res } = await querySpectacularsSetList(obj);
      this.editorSetList = res.data;
    },

    // =======
    handleCloseEditors() {
      this.handleCloseEditor = "";
      this.dialogVisibleEditor = false;
    },
    editor(row) {
      this.EditoId = row.id;
      this.dialogVisibleEditor = true;
    },
    // ==== 编辑设备

    // 查询
    getQuery() {
      this.getSupplierList();
    },
    // 重置
    getReset() {
      this.DeviceName = "";
      this.pectaculars = "";
      this.OnlineStatus = "";
      this.DisabledState = "";
      this.getSupplierList();
    },
    async setCommodity(row) {
      //设置看板
      let obj = {
        params: {
          id: this.getBoardSetId,
          spectacularsNo: this.editorSet,
          merchantNo: this.merchantNo,
          channelMerNo:this.typeinfo.channelMerNo
        },
      };
      const { data: res } = await setSpectacularsEquipment(obj);
      this.$message.success(res.msg);
      this.getSupplierList();
      this.handleCloseSet();
    },
    async examineSupplier() {
      if (this.editorBoard == "" || this.editorBoard == null)
        return this.$message.error("名称不能为空");
      //编辑
      let obj = {
        params: {
          id: this.EditoId,
          equipmentName: this.editorBoard,
        },
      };
      const { data: res } = await updateSpectacularsEquipment(obj);

      this.handleCloseEditors(); //重置
      this.getSupplierList(); //刷新
      this.$message.success(res.msg);
    },
    async compileSupplier(row) {
      //禁用 、 启用
      let obj = {
        params: {
          equipmentNo: row.equipmentNo,
        },
      };
      const { data: res } = await updateUseSpectacularsEquipment(obj);
      this.$message.success(res.msg);
      this.getSupplierList();
    },
    // =
    // 获取展示看板列表
    async getBoard() {
      let obj = {
        params: {
          isOnline: 1,
          userGroup: this.typeinfo.type,
        },
      };
      const { data: res } = await querySpectacularsPage(obj);
      this.carouselList = res.data.data;
    },

    // 获取已绑定的设备数
    async getQuipment() {
      let obj = {
        params: {
          merchantNo: this.merchantNo,
        },
      };
      if (this.typeinfo.type == "2") {
        obj.params = {
          merType: this.typeinfo.type,
          channelMerNo: this.typeinfo.channelMerNo,
        };
      }
      const { data: res } = await countSpectacularsEquipment(obj);
      this.quipmenList = res.data;
    },

    // 左切换
    getBtnLeft() {
      let _width = this.$refs.warp.clientWidth;
      let tabRef = this.$refs.tabRef.clientWidth;
      let s1 = this.$refs.s1.clientWidth;


      // if(){

      // }
    },
    getBtnRight() {
      this.$refs.tabRef.style.marginLeft = this.marginLeft + "px";
      let width = this.$refs.tabRef.style.marginLeft;
      let add = this.$refs.s1.clientWidth;
      if (width) {
        let wen = parseInt(width.substr(0, width.length - 2));
        this.marginLeft = wen + add;
        this.$refs.tabRef.style.marginLeft = this.marginLeft + "px";
      }
  
    },
    // ===============
    // 分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSupplierList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSupplierList();
    },
    //获取供应商列表
    getSupplierList() {
      let obj = {
        params: {
          page: this.currentPage, //
          limit: this.pageSize, //
          merchantNo: this.merchantNo,
          isOnline: this.OnlineStatus,
          isUse: this.DisabledState,
          equipmentName: this.DeviceName,
          spectacularsNo: this.pectaculars,
          merType: this.typeinfo.type,
          channelMerNo: this.typeinfo.channelMerNo,
        },
      };
      querySmartSpectacularsEquipmentPage(obj).then((res) => {
        this.tableData = res.data.data.data;
        this.total = res.data.data.count;
      });
    },
    async getquerySpectacularsList() {
      //
      let obj = {
        params: {
          merType: this.typeinfo.type,
          channelMerNo: this.typeinfo.channelMerNo,
        },
      };
      const { data: res } = await querySpectacularsList(obj);
      this.spectacularsList = res.data;
    },
  },

  created() {
    this.getSupplierList();
    this.getQuipment();
    this.getBoard();
    this.getquerySpectacularsList();
  },
  beforeRouteEnter(to, from, next) {
    if (to.params.ifFresh) {
      to.meta.keepAlive = false;
    } else {
      to.meta.keepAlive = true;
    }
    next();
  },
};
</script>

<style lang="less" scoped>
.warpFun {
  display: flex;
  // justify-content: space-between;
  background: #f6f7f8;
  margin-left: -1.8rem;
  min-width: 1280px;
  .recordList {
    width: 25%;
    height: 6.7rem;
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    background: #fff;
    padding: 2rem 1.8rem;
    margin-left: 1.9rem;
    .recordText {
      width: 80%;
      height: 100%;
      span {
        font-size: 3.2rem;
        color: #3f82f0;
      }
      p {
        font-size: 1.6rem;
        color: #666666;
      }
    }
    .recordImg {
      width: 20%;
      height: 6.5rem;
      background: url("~@/assets/img/icon_a1.png") no-repeat center;
      background-size: 65%;
    }
    .recordImgs {
      width: 20%;
      height: 6.5rem;
      background: url("~@/assets/img/icon_a2.png") no-repeat center;
      background-size: 65%;
    }
  }
}
.boardList {
  // height: 27rem;
  border-radius: 0.3rem;
  background: #fff;
  min-width: 1280px;
  padding-bottom: 25px;
  .boardHeaer {
    p {
      color: #666666;
      font-size: 1.6rem;
      padding-top: 1.6rem;
      padding-left: 2rem;
    }
    i {
      display: inline-block;
      width: 96%;
      border-bottom: 1px solid #e2e2e2;
      margin-left: 2rem;
    }
  }
  .carousel {
    position: relative;
    overflow: hidden;
    transition: all 0.2;
    .btnLeft {
      width: 2.5rem;
      height: 2.5rem;
      position: absolute;
      top: 50%;
      left: 12px;
      border-radius: 50%;
      background: url("~@/assets/img/icon_a6.png") no-repeat;
      background-size: 100%;
    }
    .btnRight {
      width: 2.5rem;
      height: 2.5rem;
      position: absolute;
      top: 50%;
      right: 12px;
      border-radius: 50%;
      background: url("~@/assets/img/icon_a5.png") no-repeat;
      background-size: 100%;
    }
    .carouselList {
      overflow: hidden;
      width: 90%;
      height: 70%;
      // min-width: 1440px;
      transition: all 0 ease 0;
      margin: 0 auto;
      // box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px ;
    }
  }
}
.tabshow {
  margin-top: 1.5rem;
  min-width: 1280px;
  .operation {
    width: 100%;
    min-width: 1280px;
    p {
      font-size: 1.6rem;
      padding: 1.6rem 2rem;
      color: #333333;
    }
    i {
      display: block;
      width: 95%;
      height: 1px;
      background: #ebebeb;
      margin: 0 auto;
      padding: 0 2rem;
    }
    .ActionButtons {
      padding: 1.8rem 0rem;
    }
  }
}
.meRon {
  width: 100%;
  .el-col {
    margin-left: 2rem;
    .el-input {
      /deep/ .el-input__inner {
        line-height: 34px !important;
        height: 34px;
      }
    }
    .el-button {
      padding: 9px 20px !important;
      background: #498dfe !important;
    }
    .el-select {
      /deep/ .el-input__inner {
        height: 3.4rem;
        text-align: center;
        line-height: 3.4rem;
      }
      /deep/ .el-input__icon {
        line-height: 0;
      }
    }
  }
}
.isColor {
  color: #11a600;
}
.isColorRed {
  color: #df2c2c;
}
.ShowP {
  font-size: 1.8rem;
  color: #333333;
}
.tetx {
  .flexSp();
}
.demo-image__preview {
  .el-image-viewer {
    width: 1280px !important;
  }
}
.table-warning {
  width: 20%;
  height: 30rem;
  margin: 10rem auto;
  background: url("~@/assets/new-icon/Group Copy@3x.png") no-repeat;
  background-size: 80%;
  position: relative;
}
.Shows {
  font-size: 1.8rem;
  color: #333333;
  cursor: pointer;
  float: right;
}
</style>
